import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { io, Socket } from 'socket.io-client'
import { SERVER_URL } from '../lib/constants'

interface SocketState {
  socket: Socket
  isConnected: boolean
  id: string
  setId: (id: string) => void
  connect: () => void
  disconnect: () => void
  reconnect: () => void
}

const createSocket = () => io(SERVER_URL, {
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  autoConnect: false,
})

const useSocketStore = create<SocketState>()(
  persist(
    (set, get) => ({
      socket: createSocket(),
      isConnected: false,
      id: '',
      setId: (id: string) => {
        set({
          id
        })
      },
      connect: () => {
        const { socket } = get()

        if (!socket.connected) {
          console.log('connecting in store')
          socket.connect()
          // set({
          //   id: socket.id
          // })
        }
      },
      disconnect: () => {
        const { socket } = get()
        socket.disconnect()

        set({ isConnected: false, id: '' })
        console.log('socket disconnected in store')
      },
      reconnect: () => {
        const { socket, connect } = get()
        if (socket) {
          socket.disconnect()
        }

        connect()
      },
    }),
    {
      name: 'socket-storage',
      partialize: (state) => ({ isConnected: state.isConnected }),
    }
  )
)

export default useSocketStore