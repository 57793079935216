import React, { createContext, useEffect, useState } from "react";
import { DigiPortPilotageData, DigiPortPilotageDataEntry } from "../lib/Interfaces/DigiPortPilotageData";
import useSocketStore from "../stores/socketStore";

type ListOfDigiPortPilotageData = Record<DigiPortPilotageData['vesselInformation']['name'], DigiPortPilotageDataEntry>

type DigiPortPilotageDataContextProps = {
    isEnabledForUser: boolean,
    isTrialAccount: boolean,
    checkUserAccess: (userType: number) => void 
    checkTrialAccount: (companyTier: string) => void, 
    data: ListOfDigiPortPilotageData,
    handleSetData: (receivedData: DigiPortPilotageDataEntry) => void,
    fetchData: (vesselName: string) => DigiPortPilotageDataEntry | null,
    resetContext: () => void
}

export const DigiPortPilotageDataContext = createContext<DigiPortPilotageDataContextProps>({
    isEnabledForUser: false,
    isTrialAccount: false,
    checkUserAccess: (userType: number) => null,
    checkTrialAccount: (companyTier: string) => null, 
    data: {},
    handleSetData: (receivedData: DigiPortPilotageDataEntry) => null,
    fetchData: (vesselName: string) => null,
    resetContext: () => null
})

export const DigiPortPilotageDataContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isEnabledForUser, setIsEnabledForUser] = useState<boolean>(false)
    const [isTrialAccount, setIsTrialAccount] = useState<boolean>(false)
    const { id } = useSocketStore()

    // initialise context with stale data from localstorage
    const [data, setData] = useState<ListOfDigiPortPilotageData>(() => {
        if (typeof window !== "undefined") {
            const localStorageStaleData = window.localStorage.getItem("DIGIPORT_VSIP_DATA");
            return localStorageStaleData ? JSON.parse(localStorageStaleData) as ListOfDigiPortPilotageData : {};
        }

        return {};
    })

    // whenever `data` is changed, write to localstorage
    useEffect(() => {
        if (typeof window !== "undefined") {
            window.localStorage.setItem('DIGIPORT_VSIP_DATA', JSON.stringify(data))
        } else {
            console.warn('window is not defined yet, did not write to localstorage', data)
        }
    }, [data])

    const handleSetData = (receivedData: DigiPortPilotageDataEntry) => {      
        console.log('In handleSetData context', receivedData)       
        setData(prevData => {
            console.log(prevData)
            return {
                ...prevData,
                [receivedData.data.vesselInformation.name.toLowerCase()]: receivedData
            }
        })
    }

    const fetchData = (vesselName: string) => {
        return data[vesselName.toLowerCase()] ?? null
    }

    const handleCheckIfUserIsEnabled = (userType: number) => {
        if (!isEnabledForUser && userType === 3) {
            setIsEnabledForUser(true);
        }
    }
    
    const handleCheckTrialAccount = (companyTier: string) => {
        if (!isTrialAccount && companyTier === 'TRIAL') {
            console.log('trial account setting to true')
            setIsTrialAccount(true);
        }
    }

    const resetContext = () => {
        setIsEnabledForUser(false);
        setIsTrialAccount(false);
        setData({});
    }

    return (
        <DigiPortPilotageDataContext.Provider
            value={{
                isEnabledForUser,
                isTrialAccount,
                checkUserAccess: handleCheckIfUserIsEnabled,
                checkTrialAccount: handleCheckTrialAccount,
                data,
                handleSetData,
                fetchData,
                resetContext
            }}
        >
            {children}
        </DigiPortPilotageDataContext.Provider>
    )
}  