import { PropsWithChildren, useEffect } from "react"
import useSocketStore from "../../stores/socketStore"
import { enqueueSnackbar } from "notistack"

/**
 * App Provider that contains general app state context
 */
const AppProvider = ({ children }: PropsWithChildren) => {
  const { connect, setId, socket } = useSocketStore()

  useEffect(() => {
    const handleConnect = () => {
      // setId(socket.id)
      console.log('Established live connection to server:', socket.id)
      // enqueueSnackbar(`Established live connection to server: ${socket.id}`, {
      //   variant: 'success',
      //   preventDuplicate: true
      // })
    }

    const handleWelcome = (payload: any) => {
      console.log('Received welcome event: ', payload)
      setId(payload.socket_id)
    }

    const handleConnectError = (error: any) => {
      console.error(error)
      console.log('socket connect errored: ' + error.message);
    }

    const handleDisconnect = () => {
      console.log('Disconnected live connection from server')
      // enqueueSnackbar('Disconnected live connection from server', {
      //   variant: 'warning',
      //   preventDuplicate: true
      // })
    }

    socket.on('connect_error', handleConnectError)

    socket.on('connect', handleConnect)
    socket.on('welcome', handleWelcome)
    socket.on('disconnect', handleDisconnect)

    return () => {
      socket.off('connect_error', handleConnectError)
      socket.off('connect', handleConnect)
      socket.off('welcome', handleWelcome)
      socket.off('disconnect', handleDisconnect)
    }
  }, [connect, setId, socket])

  return <>{children}</>
}

export default AppProvider