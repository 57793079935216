const getColorConfig = ({ isDark }: { isDark: boolean }): string => {
  const textColors = isDark ? '#fff' : '#000'

  return `
    .cls-1 {
      isolation: isolate;
    }
    .cls-2 {
      fill: #933f98;
    }
    .cls-2, .cls-3 {
      stroke - width: 0px;
    }
    .cls-3 {
      fill: ${textColors};
    }
  `
}

export function SgtradexLogoComponent({
  showSubtitle = false
}: {
  showSubtitle?: boolean
}) {
  const logoStyling = getColorConfig({ isDark: true })
  // Calculate the aspect ratio based on the original viewBox
  const originalWidth = 340.23
  const originalFullHeight = 149.57
  const originalMainLogoHeight = 100 // Approximate height without subtitle - adjust as needed

  // Select the appropriate height based on whether subtitle is shown
  const viewBoxHeight = showSubtitle ? originalFullHeight : originalMainLogoHeight
  const viewBox = `0 0 ${originalWidth} ${viewBoxHeight}`

  // Calculate aspect ratio (width / height)
  const aspectRatio = originalWidth / viewBoxHeight

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <svg
        id="container"
        data-name="Layer 1"
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '100%',
          width: '100%',
          maxHeight: '100%'
        }}
      >
        <defs>
          <style>
            {logoStyling}
          </style>
        </defs>
        <g>
          <g>
            <path className="cls-3" d="M33.03,88.74l-14.8-35h12.7l12.05,29.55h-7.55l12.4-29.55h11.6l-14.8,35h-11.6Z" />
            <path className="cls-3" d="M76.08,89.24c-3.33,0-6.24-.62-8.73-1.85-2.48-1.23-4.41-2.92-5.77-5.07-1.37-2.15-2.05-4.58-2.05-7.28s.68-5.22,2.03-7.35c1.35-2.13,3.2-3.8,5.55-5,2.35-1.2,4.99-1.8,7.92-1.8,2.7,0,5.19.53,7.48,1.6,2.28,1.07,4.12,2.65,5.5,4.75,1.38,2.1,2.08,4.7,2.08,7.8,0,.4-.02.84-.05,1.33-.03.48-.07.92-.1,1.33h-21.05v-5.45h15.05l-4.25,1.45c0-1.07-.19-1.98-.58-2.73-.38-.75-.91-1.33-1.58-1.75-.67-.42-1.47-.62-2.4-.62s-1.74.21-2.42.62c-.68.42-1.21,1-1.58,1.75-.37.75-.55,1.66-.55,2.73v1.7c0,1.17.23,2.17.7,3,.47.83,1.13,1.46,2,1.88.87.42,1.92.62,3.15.62s2.31-.17,3.12-.5c.82-.33,1.67-.83,2.57-1.5l5.9,5.95c-1.33,1.43-2.97,2.52-4.92,3.27s-4.29,1.12-7.02,1.12Z" />
            <path className="cls-3" d="M104.33,89.24c-2.33,0-4.63-.25-6.9-.75-2.27-.5-4.1-1.15-5.5-1.95l3.1-7.45c1.3.77,2.81,1.37,4.52,1.8,1.72.43,3.39.65,5.03.65,1.37,0,2.29-.12,2.77-.35.48-.23.73-.55.73-.95s-.28-.69-.85-.88c-.57-.18-1.32-.33-2.25-.42-.93-.1-1.96-.23-3.08-.38-1.12-.15-2.24-.38-3.38-.68-1.13-.3-2.17-.75-3.1-1.35-.93-.6-1.68-1.41-2.25-2.42-.57-1.02-.85-2.31-.85-3.88,0-1.73.52-3.3,1.57-4.7,1.05-1.4,2.62-2.52,4.7-3.38,2.08-.85,4.66-1.27,7.73-1.27,2,0,4,.18,6,.55,2,.37,3.72.93,5.15,1.7l-3.1,7.45c-1.4-.77-2.77-1.29-4.12-1.58-1.35-.28-2.61-.42-3.77-.42-1.43,0-2.41.13-2.93.4-.52.27-.77.57-.77.9,0,.4.28.7.85.9.57.2,1.32.35,2.25.45.93.1,1.96.23,3.08.38,1.12.15,2.23.38,3.35.7,1.12.32,2.14.77,3.08,1.38.93.6,1.68,1.41,2.25,2.42.57,1.02.85,2.31.85,3.88s-.52,3.16-1.55,4.58c-1.03,1.42-2.6,2.55-4.7,3.4s-4.73,1.27-7.9,1.27Z" />
            <path className="cls-3" d="M132.58,89.24c-2.33,0-4.63-.25-6.9-.75-2.27-.5-4.1-1.15-5.5-1.95l3.1-7.45c1.3.77,2.81,1.37,4.52,1.8,1.72.43,3.39.65,5.03.65,1.37,0,2.29-.12,2.77-.35.48-.23.73-.55.73-.95s-.28-.69-.85-.88c-.57-.18-1.32-.33-2.25-.42-.93-.1-1.96-.23-3.08-.38-1.12-.15-2.24-.38-3.38-.68-1.13-.3-2.17-.75-3.1-1.35-.93-.6-1.68-1.41-2.25-2.42-.57-1.02-.85-2.31-.85-3.88,0-1.73.52-3.3,1.57-4.7,1.05-1.4,2.62-2.52,4.7-3.38,2.08-.85,4.66-1.27,7.73-1.27,2,0,4,.18,6,.55,2,.37,3.72.93,5.15,1.7l-3.1,7.45c-1.4-.77-2.77-1.29-4.12-1.58-1.35-.28-2.61-.42-3.77-.42-1.43,0-2.41.13-2.93.4-.52.27-.77.57-.77.9,0,.4.28.7.85.9.57.2,1.32.35,2.25.45.93.1,1.96.23,3.08.38,1.12.15,2.23.38,3.35.7,1.12.32,2.14.77,3.08,1.38.93.6,1.68,1.41,2.25,2.42.57,1.02.85,2.31.85,3.88s-.52,3.16-1.55,4.58c-1.03,1.42-2.6,2.55-4.7,3.4s-4.73,1.27-7.9,1.27Z" />
            <path className="cls-3" d="M165.23,89.24c-3.33,0-6.24-.62-8.73-1.85-2.48-1.23-4.41-2.92-5.77-5.07-1.37-2.15-2.05-4.58-2.05-7.28s.68-5.22,2.03-7.35c1.35-2.13,3.2-3.8,5.55-5,2.35-1.2,4.99-1.8,7.92-1.8,2.7,0,5.19.53,7.48,1.6,2.28,1.07,4.12,2.65,5.5,4.75,1.38,2.1,2.07,4.7,2.07,7.8,0,.4-.02.84-.05,1.33-.03.48-.07.92-.1,1.33h-21.05v-5.45h15.05l-4.25,1.45c0-1.07-.19-1.98-.57-2.73-.38-.75-.91-1.33-1.58-1.75-.67-.42-1.47-.62-2.4-.62s-1.74.21-2.43.62c-.68.42-1.21,1-1.58,1.75-.37.75-.55,1.66-.55,2.73v1.7c0,1.17.23,2.17.7,3,.47.83,1.13,1.46,2,1.88.87.42,1.92.62,3.15.62s2.31-.17,3.12-.5c.82-.33,1.68-.83,2.58-1.5l5.9,5.95c-1.33,1.43-2.97,2.52-4.92,3.27-1.95.75-4.29,1.12-7.03,1.12Z" />
            <path className="cls-3" d="M182.78,88.74v-37.1h11.3v37.1h-11.3Z" />
            <path className="cls-2" d="M199.43,88.74v-35h9.7l17.8,21.3h-4.5v-21.3h11.5v35h-9.7l-17.8-21.3h4.5v21.3h-11.5Z" />
            <path className="cls-2" d="M253.73,89.24c-3.1,0-5.84-.61-8.23-1.83-2.38-1.22-4.26-2.89-5.62-5.02-1.37-2.13-2.05-4.58-2.05-7.35s.68-5.22,2.05-7.35c1.37-2.13,3.24-3.8,5.62-5,2.38-1.2,5.12-1.8,8.23-1.8s5.85.6,8.25,1.8,4.27,2.87,5.62,5c1.35,2.13,2.02,4.58,2.02,7.35s-.67,5.22-2.02,7.35c-1.35,2.13-3.23,3.81-5.62,5.02-2.4,1.22-5.15,1.83-8.25,1.83ZM253.73,80.44c.83,0,1.58-.2,2.25-.6.67-.4,1.2-1.01,1.6-1.83.4-.82.6-1.81.6-2.98s-.2-2.19-.6-2.97c-.4-.78-.93-1.38-1.6-1.78-.67-.4-1.42-.6-2.25-.6s-1.58.2-2.25.6c-.67.4-1.2.99-1.6,1.78-.4.78-.6,1.77-.6,2.97s.2,2.16.6,2.98c.4.82.93,1.43,1.6,1.83.67.4,1.42.6,2.25.6Z" />
            <path className="cls-2" d="M279.88,88.74l-9.5-27.35h10.7l7.2,22.2h-5.15l7.55-22.2h9.6l7.2,22.2h-5l7.45-22.2h9.9l-9.5,27.35h-11l-5.75-17.6h3.3l-6,17.6h-11Z" />
          </g>
          <g>
            <path className="cls-2" d="M193.14,34.27c-1.02-.13-70.64,19.84-70.08,19.19.56-.64,24.6-22.24,40.21-29.17s41.47-11.15,56.12-8.76c14.65,2.39,32.15,8.01,31.55,8.05s-9.32-3.77-35.01.04c-25.69,3.81-33.4,5.76-33.4,5.76" />
            <path className="cls-3" d="M253.8,81.98c10.06-3.14,16.35-18.24,14.49-28.81-3.75-13.32-61.77-21.68-136.42,15.15,0,0,31.87-28.29,87.25-30.51,55.38-2.21,76.91-.43,76.91-.43,0,0-5.65,18.66-18.61,34.32-12.96,15.66-12.93,16.95-26.4,16.7" />
          </g>
        </g>
        {showSubtitle && (<g>
          <g>
            <path className="cls-3" d="M90.87,125.87v-8.05h3.49c.72,0,1.34.12,1.86.35.52.23.92.57,1.21,1.01.28.44.43.96.43,1.55s-.14,1.13-.43,1.56c-.28.43-.69.77-1.21,1.01-.52.24-1.14.36-1.86.36h-2.46l.85-.84v3.06h-1.88ZM92.74,123.02l-.85-.89h2.36c.57,0,1-.12,1.29-.37.29-.25.44-.59.44-1.04s-.15-.79-.44-1.03c-.29-.24-.72-.36-1.29-.36h-2.36l.85-.89v4.57Z" />
            <path className="cls-3" d="M102.1,125.96c-.65,0-1.23-.14-1.74-.41-.51-.28-.91-.65-1.2-1.13-.29-.48-.44-1.03-.44-1.64s.15-1.17.44-1.65c.29-.48.69-.86,1.2-1.13.51-.27,1.09-.41,1.74-.41s1.25.14,1.76.41c.51.27.92.65,1.21,1.13s.44,1.03.44,1.65-.15,1.17-.44,1.65c-.29.48-.69.85-1.21,1.13s-1.1.41-1.76.41ZM102.1,124.49c.31,0,.58-.07.81-.2.23-.13.42-.33.56-.59.14-.26.21-.57.21-.92s-.07-.67-.21-.92c-.14-.25-.33-.45-.56-.58-.23-.13-.5-.2-.8-.2s-.57.07-.81.2c-.24.13-.43.33-.57.58s-.21.56-.21.92.07.66.21.92.33.46.57.59c.24.13.5.2.79.2Z" />
            <path className="cls-3" d="M108.11,125.87l-2.23-6.19h1.69l1.85,5.33h-.82l1.93-5.33h1.53l1.86,5.33h-.78l1.9-5.33h1.59l-2.24,6.19h-1.73l-1.66-4.57h.52l-1.68,4.57h-1.74Z" />
            <path className="cls-3" d="M120.55,125.96c-.71,0-1.32-.14-1.85-.41-.53-.28-.94-.65-1.23-1.13-.29-.48-.44-1.03-.44-1.64s.14-1.16.42-1.65c.28-.48.67-.86,1.17-1.13.5-.27,1.07-.41,1.7-.41s1.15.13,1.64.39c.49.26.87.63,1.16,1.1.28.48.43,1.05.43,1.71,0,.07,0,.15-.01.24,0,.09-.02.17-.02.25h-5.03v-1.05h4.08l-.7.31c0-.32-.05-.6-.18-.84-.13-.24-.31-.42-.54-.56-.23-.13-.5-.2-.81-.2s-.58.07-.81.2c-.23.13-.42.32-.55.56-.13.24-.2.53-.2.86v.28c0,.34.08.64.23.9.15.25.36.45.63.58.27.13.59.2.97.2.32,0,.61-.05.86-.15.25-.1.48-.25.68-.45l.96,1.04c-.28.32-.64.57-1.07.74-.43.17-.92.26-1.48.26Z" />
            <path className="cls-3" d="M124.74,125.87v-6.19h1.71v1.76l-.24-.52c.18-.44.48-.77.89-1,.41-.23.9-.34,1.48-.34v1.67c-.07-.02-.14-.03-.2-.03-.07,0-.13-.01-.19-.01-.51,0-.91.14-1.21.43-.3.28-.45.72-.45,1.31v2.92h-1.79Z" />
            <path className="cls-3" d="M132.77,125.96c-.71,0-1.32-.14-1.85-.41s-.94-.65-1.23-1.13-.44-1.03-.44-1.64.14-1.16.42-1.65c.28-.48.67-.86,1.17-1.13.5-.27,1.07-.41,1.7-.41s1.15.13,1.64.39c.49.26.87.63,1.16,1.1.28.48.43,1.05.43,1.71,0,.07,0,.15-.01.24,0,.09-.02.17-.02.25h-5.03v-1.05h4.08l-.7.31c0-.32-.05-.6-.18-.84-.13-.24-.31-.42-.54-.56-.23-.13-.5-.2-.81-.2s-.58.07-.81.2c-.23.13-.42.32-.55.56s-.2.53-.2.86v.28c0,.34.08.64.23.9.15.25.36.45.63.58.27.13.59.2.97.2.32,0,.61-.05.86-.15.25-.1.48-.25.68-.45l.96,1.04c-.28.32-.64.57-1.07.74-.43.17-.92.26-1.48.26Z" />
            <path className="cls-3" d="M139.62,125.96c-.58,0-1.11-.13-1.58-.4-.47-.26-.84-.63-1.1-1.11-.27-.48-.4-1.04-.4-1.68s.13-1.2.4-1.68c.27-.48.64-.85,1.1-1.11.47-.26.99-.4,1.58-.4.52,0,.98.11,1.37.34.39.23.69.58.91,1.05.21.47.32,1.07.32,1.79s-.1,1.32-.31,1.8-.5.83-.89,1.05c-.39.23-.85.34-1.4.34ZM139.92,124.49c.3,0,.57-.07.81-.2.24-.13.43-.33.57-.59s.21-.57.21-.92-.07-.67-.21-.92-.33-.45-.57-.58c-.24-.13-.51-.2-.81-.2s-.56.07-.79.2c-.24.13-.43.33-.57.58-.14.25-.21.56-.21.92s.07.66.21.92c.14.26.33.46.57.59.24.13.5.2.79.2ZM141.55,125.87v-1.27l.03-1.84-.11-1.83v-3.6h1.79v8.54h-1.71Z" />
            <path className="cls-3" d="M148.18,125.87v-8.54h1.79v3.6l-.11,1.83.03,1.84v1.27h-1.71ZM151.83,125.96c-.53,0-.99-.11-1.39-.34s-.7-.58-.9-1.05-.31-1.07-.31-1.8.11-1.33.33-1.79.52-.82.91-1.05c.39-.23.84-.34,1.36-.34.59,0,1.12.13,1.58.4.46.26.83.63,1.1,1.11.27.48.41,1.04.41,1.68s-.14,1.2-.41,1.68c-.27.48-.64.85-1.1,1.11-.46.26-.99.4-1.58.4ZM151.53,124.49c.3,0,.57-.07.8-.2.23-.13.42-.33.56-.59.14-.26.21-.57.21-.92s-.07-.67-.21-.92c-.14-.25-.33-.45-.56-.58-.23-.13-.5-.2-.8-.2s-.57.07-.81.2c-.24.13-.43.33-.57.58s-.21.56-.21.92.07.66.21.92.33.46.57.59c.24.13.51.2.81.2Z" />
            <path className="cls-3" d="M156.9,128.2c-.31,0-.63-.05-.94-.15-.31-.1-.58-.24-.78-.41l.66-1.29c.14.13.3.23.48.3.18.07.36.1.55.1.25,0,.44-.06.59-.18.15-.12.29-.32.41-.59l.32-.76.14-.2,2.23-5.34h1.71l-2.8,6.57c-.2.51-.43.9-.68,1.18-.26.28-.54.48-.85.59-.31.11-.65.17-1.03.17ZM157.96,126.11l-2.75-6.43h1.84l2.15,5.19-1.24,1.24Z" />
          </g>
          <g>
            <g id="sg">
              <g className="cls-1">
                <path className="cls-3" d="M169.2,126.01h2.95c0,.51.17.93.51,1.26.34.33.77.5,1.27.5s.89-.17,1.22-.51c.33-.34.5-.77.5-1.27s-.13-.85-.38-1.11c-.25-.25-.73-.51-1.44-.78l-.63-.23c-1.39-.51-2.33-1.06-2.81-1.65-.68-.84-1.03-1.82-1.03-2.95,0-1.3.44-2.4,1.32-3.31.88-.9,1.95-1.36,3.21-1.36s2.29.44,3.16,1.31c.87.87,1.32,1.95,1.34,3.23h-2.91c-.07-1.04-.58-1.56-1.56-1.56-.44,0-.82.16-1.13.48-.31.32-.46.71-.46,1.18,0,.86.56,1.47,1.69,1.82l1.03.33c1.1.35,1.92.85,2.45,1.49.77.93,1.16,1.98,1.16,3.14,0,1.28-.46,2.39-1.39,3.34-.93.95-2.02,1.42-3.28,1.42-.84,0-1.64-.22-2.42-.65-.77-.43-1.36-.99-1.77-1.69-.41-.7-.61-1.51-.61-2.43Z" />
                <path className="cls-3" d="M188.92,114.79v17.11c0,1.3-.23,2.31-.7,3.01-.93,1.43-2.27,2.15-4.04,2.15-1.32,0-2.44-.46-3.36-1.39-.92-.93-1.37-2.06-1.37-3.41h3.14c.11,1.08.64,1.62,1.59,1.62.79,0,1.29-.35,1.49-1.06.07-.2.1-.51.1-.93v-2.98c-.71,1.21-1.71,1.82-3.01,1.82s-2.25-.57-2.91-1.72c-.26-.46-.4-1.2-.4-2.22v-8.77c0-.99.31-1.81.94-2.47.63-.65,1.43-.98,2.4-.98,1.21,0,2.21.56,2.98,1.69v-1.49h3.14ZM185.78,125.61v-5.99c0-.6-.1-1.03-.3-1.29-.33-.44-.76-.66-1.29-.66-.42,0-.79.15-1.11.45-.32.3-.48.8-.48,1.51v5.99c0,.57.07.97.2,1.19.31.53.78.79,1.42.79,1.04,0,1.56-.66,1.56-1.99Z" />
              </g>
            </g>
            <g id="tra" className="cls-1">
              <g className="cls-1">
                <path className="cls-3" d="M195.31,117.24v8.82c0,.73.13,1.16.4,1.29.18.09.45.13.83.13h.73v3.01h-.99c-1.63,0-2.82-.57-3.57-1.72-.35-.51-.53-1.21-.53-2.11v-9.42h-1.55v-2.48h1.55v-4.79h3.14v4.79h1.95v2.48h-1.95Z" />
                <path className="cls-3" d="M201.52,114.76v1.69c1.04-1.19,2.4-1.83,4.1-1.92v3.27c-.37-.09-.69-.13-.96-.13-.97,0-1.78.39-2.45,1.16-.46.55-.69,1.37-.69,2.45v9.22h-3.14v-15.73h3.14Z" />
                <path className="cls-3" d="M212.52,130.49v-1.59c-.68,1.19-1.67,1.78-2.97,1.78-.71,0-1.35-.21-1.95-.63-.59-.42-1-.96-1.22-1.62-.11-.35-.17-.87-.17-1.55v-1.78c0-1.43.57-2.57,1.72-3.4.64-.46,1.57-.69,2.81-.69h1.78v-1.35c0-.59-.1-1.02-.3-1.29-.33-.44-.75-.66-1.26-.66-.95,0-1.48.51-1.59,1.52h-3.17c.04-1.32.52-2.43,1.42-3.32.9-.89,2.02-1.34,3.34-1.34s2.38.46,3.3,1.37c.93.91,1.39,2.01,1.39,3.29v11.27h-3.14ZM212.52,123.58h-1.19c-1.3,0-1.95.5-1.95,1.49v.76c0,1.15.54,1.72,1.62,1.72.51,0,.9-.19,1.19-.56.22-.29.33-.91.33-1.88v-1.52Z" />
              </g>
            </g>
            <g id="de">
              <g className="cls-1">
                <path className="cls-3" d="M226.39,108.47v22.08h-3.14v-1.62c-.73,1.21-1.72,1.82-2.98,1.82-1.06,0-1.93-.43-2.61-1.29-.46-.6-.7-1.58-.7-2.95v-8.51c0-.97.32-1.78.96-2.43.64-.65,1.43-.98,2.38-.98,1.28,0,2.26.56,2.95,1.69v-7.81h3.14ZM223.24,125.48v-5.66c0-.73-.12-1.22-.36-1.49-.29-.4-.69-.6-1.22-.6-.62,0-1.08.28-1.39.83-.11.2-.17.62-.17,1.26v5.66c0,.77.13,1.3.4,1.59.31.35.71.53,1.19.53.4,0,.76-.15,1.08-.45.32-.3.48-.85.48-1.67Z" />
                <path className="cls-3" d="M230.86,123.79v1.62c0,.79.14,1.36.43,1.69.29.33.66.5,1.13.5.93,0,1.46-.54,1.59-1.62h3.14c0,1.3-.46,2.42-1.37,3.36-.92.94-2.02,1.41-3.33,1.41-.84,0-1.64-.23-2.4-.68-.76-.45-1.34-1.05-1.74-1.8-.4-.75-.6-1.7-.6-2.85v-5.96c0-1.35.46-2.49,1.39-3.44.93-.95,2.04-1.42,3.34-1.42s2.47.46,3.36,1.39c.89.93,1.34,2.09,1.34,3.48v4.34h-6.29ZM234,121.24v-1.59c0-1.28-.52-1.92-1.56-1.92-.6,0-1.06.26-1.39.79-.13.22-.2.6-.2,1.13v1.59h3.14Z" />
              </g>
            </g>
            <g id="Subtraction_6" data-name="Subtraction 6">
              <path className="cls-3" d="M247.65,130.52h-2.81l-.72-1.71,1.45-3.22,2.08,4.93h0ZM240.01,130.52h-2.81l4-8.1-3.74-7.64h2.81l3.74,7.64-4,8.1h0ZM245.57,119.24h0l-1.36-3.16.6-1.31h2.81l-2.05,4.46h0Z" />
            </g>
          </g>
        </g>)}
      </svg>
    </div>
  )
}