import React, { useContext, useEffect, useRef, useState } from "react";

import { CalendarMonth, Settings } from "@mui/icons-material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import { AnimatePresence, motion } from "framer-motion";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuid } from 'uuid';
import {
  addBookmark,
  getBookmarks,
  getCompanyTier,
  getUserType,
  getVesselLocation,
  removeBookmark,
} from "../api/API";
import DeletionConfirmationModal from "../components/DeletionConfirmationModal";
import DueToArriveSideBarContent from "../components/DueToArriveSideBarContent";
import SideBarContent from "../components/SideBarContent";
import VesselMapV2 from "../components/VesselMapV2";
import { DigiPortPilotageDataContext } from "../contexts/DigiPortPilotageDataContext";
import {
  AppRoutes,
  checkIMOFormat,
  parseVesselMapObj,
  parseWebSocketPilotage,
  themeColors
} from "../lib/constants";
import { DigiPortPilotageData, VesselInformation, VoyageInformation } from "../lib/Interfaces/DigiPortPilotageData";
import VesselInfo from "../lib/Interfaces/VesselInfo";
import VesselMapObj from "../lib/Interfaces/VesselMapObj";
import { WebsocketEvents } from "../lib/Interfaces/WebsocketEvents";
import useAuthStore from "../stores/authStore";
import useSocketStore from "../stores/socketStore";
import { sharedFlexCenter } from "../styles/global";
import PilotageInfo from "../lib/Interfaces/PilotageInfo";

interface VesselLocationProps {
  vessels: VesselMapObj[];
}

//Moved out so animation doesnt fire each rerender
const SideBarContainer = styled(motion.div)`
  padding: 5px;
  background-color: ${themeColors.sidebar};
  overflow-x: hidden;
`;
const Container = styled.div`
  height: 100%;
  ${sharedFlexCenter}
`;

const VesselLocation: React.FC<VesselLocationProps> = () => {
  const isLargeScreen = useMediaQuery({ maxWidth: 1024 });
  const [vessels, setVessels] = useState<VesselMapObj[]>([]);
  const [openDeletionConfirmationModal, setOpenDeletionConfirmationModal] =
    useState<Boolean>(false);
  const [selectedVessel, setSelectedVessel] = useState<VesselMapObj[]>([]);
  const [sideBar, setSideBar] = useState(true);
  const [selectedSideBar, setSelectedSideBar] = useState("search");
  const [vesselPopup, setVesselPopup] = useState("");
  const [availColors, setAvailColors] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
  ]);
  const [forceRefresh, setForceRefresh] = useState(0);
  const [userType, setUserType] = useState(0);
  const { logout } = useAuthStore()

  const { checkUserAccess, checkTrialAccount, handleSetData } = useContext(
    DigiPortPilotageDataContext
  );
  const { socket, id: socketId } = useSocketStore()

  const navigate = useNavigate()

  //Called when page is first loaded
  useEffect(() => {
    async function fetchData() {
      // Call to get UserType here
      const userType = await getUserType();
      const companyTier = await getCompanyTier();
      setUserType(userType.account_type);
      checkUserAccess(userType.account_type);
      checkTrialAccount(companyTier);
      let bookmarkedVessels: any[];
      if (userType.account_type > 1) {
        bookmarkedVessels = (await getBookmarks()) ?? [];
      } else {
        bookmarkedVessels = [];
      }

      // If there is vessels stored in local storage
      if (
        window.localStorage.getItem("VESSELS") != null &&
        window.localStorage.getItem("COLOR") != null
      ) {
        let vessels = JSON.parse(window.localStorage.getItem("VESSELS") ?? "");
        vessels = vessels.map(parseVesselMapObj);
        let colors = JSON.parse(window.localStorage.getItem("COLOR") ?? "");
        //Compare localStorage vessels and bookmark -> Obtain a unique set of vessels
        const colorCopy = [...colors];
        let combinedVessels = consolidateVessels(
          vessels,
          bookmarkedVessels ?? []
        );
        //Assign colors to vessels
        for (const vessel of combinedVessels) {
          if (vessel.colorIndex == -1) {
            vessel.colorIndex = colorCopy.pop();
          }
        }
        setVessels(combinedVessels);
        setAvailColors(colorCopy);
        //Update local storage vessels and color
        window.localStorage.setItem("COLOR", JSON.stringify(colorCopy));
        window.localStorage.setItem("VESSELS", JSON.stringify(combinedVessels));
        //For the local storage vessels without any pilotage info, retrieve it. This means that page was reloaded when vessel was in the midst of querying pilotage information
      } else if (bookmarkedVessels.length > 0) {
        //If there is no colors set the default 10 color indexes
        let colors = window.localStorage.getItem("COLOR")
          ? JSON.parse(window.localStorage.getItem("COLOR") ?? "")
          : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        const colorCopy = [...colors];
        //Assign colors to each of the bookmarked vessels
        for (const vessel of bookmarkedVessels) {
          if (vessel.colorIndex === -1) {
            vessel.colorIndex = colorCopy.pop();
          }
        }
        setSideBar(true);
        setVessels(bookmarkedVessels);
        setAvailColors(colorCopy);
        window.localStorage.setItem("COLOR", JSON.stringify(colorCopy));
        window.localStorage.setItem(
          "VESSELS",
          JSON.stringify(bookmarkedVessels)
        );
      }
    }
    fetchData();

    socket.on(
      WebsocketEvents.VSIP_RECEIVED,
      (message: DigiPortPilotageData) => {
        console.log(message)
        handleSetData({
          data: message,
          timestamp: new Date().toISOString(),
        });
      }
    );

    socket.on(WebsocketEvents.PILOTAGE_SERVICE_RECEIVED, (data: any) => {
      const processedPilotage = parseWebSocketPilotage(data);
      console.log(processedPilotage);
      setVessels((prev) => {
        const newVessels = prev.map((vessel) => {
          if (
            vessel.IMO === String(processedPilotage.allPilotage[0].pilotage_imo)
          ) {
            vessel.hasPilotageData = true;
            vessel.pilotageInfo = processedPilotage.allPilotage;
            vessel.currentPilotageInfo = processedPilotage.currentPilotage;

            enqueueSnackbar(
              <div
                className="hover:cursor-pointer"
                onClick={() => {
                  handleNavigateToVessel(vessel);
                  closeSnackbar();
                }}
              >
                {`Pilotage Data received for ${vessel.name} (${vessel.IMO})`}
              </div>,
              {
                variant: "info",
              }
            );
          }
          return vessel;
        });
        return newVessels;
      });
    });
  }, []);

  function consolidateVessels(
    list1: VesselMapObj[],
    list2: VesselMapObj[]
  ): VesselMapObj[] {
    // Create a Set to store unique IMOs
    const uniqueIMOs = new Set<string>();

    // Create a result array
    const consolidatedVessels: VesselMapObj[] = [];

    // Process the first list
    for (const vessel of list1) {
      const { IMO } = vessel;
      if (!uniqueIMOs.has(IMO)) {
        uniqueIMOs.add(IMO);
        consolidatedVessels.push(vessel);
      }
    }

    // Process the second list
    for (const vessel of list2) {
      const { IMO } = vessel;
      if (!uniqueIMOs.has(IMO)) {
        uniqueIMOs.add(IMO);
        consolidatedVessels.push(vessel);
      } else {
        //If the vessel is already in the list, make sure that it is bookmarked
        const inTarget = list1.find((x) => x.IMO == IMO);
        const vesselIndex = consolidatedVessels.indexOf(inTarget ?? vessel);
        if (vesselIndex > -1) {
          consolidatedVessels[vesselIndex] = {
            ...consolidatedVessels[vesselIndex],
            bookmarked: true,
          };
        }
      }
    }

    return consolidatedVessels;
  }

  function pilotageTimeout(imos: string[]) {
    imos.forEach((imo) => {
      const to = setTimeout(() => {
        //Check if the vesselPilotage is in? If it not in then set the pilotage to null.
        //If active timer is on. Logic to update updated and to update pilotage info?
        //Currently double firing.
        setVessels((prev) => {
          const newVessels = prev.map((vessel) => {
            if (vessel.IMO === imo) {
              //Checks if the vessel's data is in prog
              if (vessel.hasPilotageData === null) {
                enqueueSnackbar(
                  <div
                    className="hover:cursor-pointer"
                    onClick={() => {
                      handleNavigateToVessel(vessel);
                    }}
                  >
                    {`Pilotage Data for ${vessel.name} (${vessel.IMO}) timed out`}
                  </div>,
                  {
                    variant: "error",
                  }
                );
                //So what if we learn that the new incoming info is not there. We just need to update the alert right. No we need to check if the imo is alr in.
                //Can we convert pilotage check to a list that appends checked imos?
                vessel.hasPilotageData = false;
                vessel.pilotageInfo = [
                  {
                    pilotage_imo: null,
                    pilotage_cst_dt_time: null,
                    pilotage_arrival_dt_time: null,
                    pilotage_onboard_dt_time: null,
                    pilotage_start_dt_time: null,
                    pilotage_end_dt_time: null,
                    pilotage_snapshot_dt: null,
                    pilotage_nm: null,
                    pilotage_loc_to_code: null,
                    pilotage_loc_from_code: null,
                  },
                ];
              }
            }
            return vessel;
          });
          window.localStorage.setItem("VESSELS", JSON.stringify(newVessels));
          return newVessels;
        });
      }, 1 * 60000);
    });
  }

  //Called when users logout
  function handleLogout() {
    window.localStorage.removeItem("ALERTS");
    window.localStorage.removeItem("COLOR");
    window.localStorage.removeItem("VESSELS");
    logout()
    navigate(AppRoutes.Login)
  }

  //Called when bookmark symbol is clicked on associated vessel card
  async function handleBookmark(imo: string) {
    const vesselInTarget = vessels.find((vessel) => vessel.IMO === imo);
    if (vesselInTarget) {
      if (vesselInTarget?.bookmarked) {
        const isRemoved = await removeBookmark(vesselInTarget.id);
        if (isRemoved?.status === 204) {
          setVessels((prev) => {
            const newVessels = prev.map((vessel) => {
              if (vessel.IMO === imo) {
                vessel.bookmarked = false;
              }
              return vessel;
            });
            window.localStorage.setItem("VESSELS", JSON.stringify(newVessels));
            return newVessels;
          });
        } else if (isRemoved?.status === 401) {
          handleLogout();
        } else {
          enqueueSnackbar(
            "Failed to remove " + vesselInTarget?.name + " from bookmark",
            {
              variant: "error",
            }
          );
        }
      } else {
        const isAdded = await addBookmark(vesselInTarget);
        console.log(isAdded);
        if (isAdded?.status === 200) {
          setVessels((prev) => {
            const newVessels = prev.map((vessel) => {
              if (vessel.IMO === imo) {
                vessel.id = isAdded.data.id;
                vessel.bookmarked = true;
              }
              return vessel;
            });
            window.localStorage.setItem("VESSELS", JSON.stringify(newVessels));
            return newVessels;
          });
        } else if (isAdded?.status === 401) {
          handleLogout();
        } else {
          enqueueSnackbar(
            "Failed to add " + vesselInTarget?.name + " to bookmark",
            {
              variant: "error",
            }
          );
        }
      }
    }
  }

  //Called when vessel is removed from the fleet. ColorIndex is returned.
  function returnColor(index: number) {
    if (index < 0) {
      return;
    }
    setAvailColors((prev) => {
      const newColors = [...prev, index];
      window.localStorage.setItem("COLOR", JSON.stringify(newColors));
      return newColors;
    });
  }

  function handleSetAvailColors(newColorIndexes: number[]) {
    setAvailColors(newColorIndexes);
    window.localStorage.setItem("COLOR", JSON.stringify(newColorIndexes));
  }

  //Process imo to check if imo is suitable to request
  function checkVesselInFleet(imo: string) {
    if (!checkIMOFormat(imo)) {
      enqueueSnackbar("Invalid IMO provided! " + imo, {
        variant: "error",
      });
      return true;
    }

    const vesselInFleet = vessels.find((vl) => vl.IMO === imo);

    if (vesselInFleet) {
      enqueueSnackbar(
        <div
          className="hover:cursor-pointer"
          onClick={() => {
            handleNavigateToVessel(vesselInFleet);
          }}
        >
          {"Vessel already in fleet! " + vesselInFleet.name + " (" + imo + ")"}
        </div>,
        {
          variant: "error",
        }
      );
    }

    return vesselInFleet;
  }

  //Add a new imo paired with colorIndex to fleet
  async function handleAddToFleetIMO(imo: string, colorIndex: number) {
    if (vessels.length === 10) {
      enqueueSnackbar("Max number of vessels reached!", {
        variant: "error",
      });
    } else {
      const vesselMapObj: VesselMapObj = {
        id: uuid(),
        name: "",
        type: "",
        latitude: "",
        longitude: "",
        IMO: imo,
        MMSI: "",
        visible: true,
        course: 0,
        flag: "",
        loading: false,
        colorIndex: colorIndex,
        pilotageInfo: null,
        currentPilotageInfo: null,
        bookmarked: false,
        aliases: "",
        eta: null,
        etd: null,
        dueToArrive: null,
        dueToDepart: null,
        callsign: null,
        speed: 0,
        destination: null,
        timeStamp: null,
        hasPilotageData: null,
        locationFrom: "",
        locationTo: "",
      };
      setVessels((prev) => {
        const newVessels = [...prev, vesselMapObj];
        return newVessels;
      });
    }
  }

  async function handleAddToFleetName(vessel: VesselInfo, colorIndex: number) {
    if (vessels.length === 10) {
      enqueueSnackbar("Max number of vessels reached!", {
        variant: "error",
      });
    } else if (
      vessels.find((existingVessel) => existingVessel.IMO === vessel.vesselImo)
    ) {
      enqueueSnackbar(
        `Vessel ${vessel.vesselName} (${vessel.vesselImo}) already in fleet!`,
        {
          variant: "error",
        }
      );
    } else {
      const vesselMapObj: VesselMapObj = {
        id: uuid(),
        name: vessel.vesselName,
        type: vessel.vesselType,
        latitude: "",
        longitude: "",
        IMO: vessel.vesselImo,
        MMSI: vessel.vesselMMSI,
        flag: vessel.vesselFlag,
        visible: true,
        course: 0,
        aliases: "",
        loading: true,
        colorIndex: colorIndex,
        pilotageInfo: null,
        currentPilotageInfo: null,
        bookmarked: false,
        eta: null,
        etd: null,
        dueToArrive: null,
        dueToDepart: null,
        callsign: vessel.vesselCallsign ?? "",
        speed: 0,
        destination: null,
        timeStamp: null,
        hasPilotageData: null,
        locationFrom: "",
        locationTo: "",
      };
      setVessels((prev) => {
        const newVessels = [...prev, vesselMapObj];
        return newVessels;
      });
    }
  }

  //Called when updated vessel information is received. Updates vessel information
  function updateFleet(updatedVessel: VesselMapObj) {
    console.log(updatedVessel);
    setVessels((prev) => {
      const newVessels = prev.map((vessel) => {
        if (vessel.IMO === updatedVessel.IMO) {
          vessel.name = updatedVessel.name;
          vessel.course = updatedVessel.course;
          vessel.latitude = updatedVessel.latitude;
          vessel.longitude = updatedVessel.longitude;
          vessel.eta = updatedVessel.eta;
          vessel.etd = updatedVessel.etd;
          vessel.flag = vessel.flag !== "" ? vessel.flag : updatedVessel.flag;
          vessel.MMSI = updatedVessel.MMSI;
          vessel.type = vessel.type !== "" ? vessel.type : updatedVessel.type;
          vessel.callsign = updatedVessel.callsign;
          vessel.speed = updatedVessel.speed;
          vessel.timeStamp = updatedVessel.timeStamp;
          vessel.destination = updatedVessel.destination;
          vessel.aliases = updatedVessel.aliases;
          vessel.loading = false;
          vessel.dueToArrive = updatedVessel.dueToArrive;
          vessel.dueToDepart = updatedVessel.dueToDepart;
          vessel.locationFrom = updatedVessel.locationFrom;
          vessel.locationTo = updatedVessel.locationTo;
        }
        return vessel;
      });
      window.localStorage.setItem("VESSELS", JSON.stringify(newVessels));
      return newVessels;
    });
  }

  //Open deletion confirmation modal
  function handleRemoveVessel(vessel: VesselMapObj) {
    // Open modal
    setOpenDeletionConfirmationModal(true);
  }

  function handleSelectedVesselsRemove() {
    setVessels((prev) => {
      const newVessels = prev.filter(
        (pv) => !selectedVessel.some((sv) => sv.id === pv.id)
      );
      if (newVessels.length !== 0) {
        window.localStorage.setItem("VESSELS", JSON.stringify(newVessels));
      } else {
        window.localStorage.removeItem("VESSELS");
      }
      return newVessels;
    });

    let colorBasket: number[] = [];
    // Return colors for all removed vessels
    selectedVessel.forEach((vessel) => colorBasket.push(vessel.colorIndex));
    handleSetAvailColors([...availColors, ...colorBasket]);

    // Notify for each removed vessel
    selectedVessel.forEach((vessel) => {
      enqueueSnackbar(`${vessel.name} removed from tracking!`, {
        variant: "error",
      });
    });

    // Clear the selection after removal
    setSelectedVessel([]);
    setOpenDeletionConfirmationModal(false);
  }

  function handleRemoveVesselIMO(vessel: string) {
    setVessels((prev) => {
      const vesselInTarget = prev.find((pv) => pv.IMO === vessel);
      returnColor(vesselInTarget?.colorIndex ?? -1);
      enqueueSnackbar(`Failed to retrieve location details for ${vessel}!`, {
        variant: "error",
      });
      const newVessels = prev.filter((pv) => pv.IMO !== vessel);
      if (newVessels.length !== 0) {
        window.localStorage.setItem("VESSELS", JSON.stringify(newVessels));
      } else {
        window.localStorage.removeItem("VESSELS");
      }
      return newVessels;
    });
  }

  //Look at token field to perform validation
  function checkSearchLimit(imoListLength: number) {
    //Hard coded. To change to read from actual user concurrent search
    const search_limit = 3;
    if (imoListLength > search_limit) {
      enqueueSnackbar(
        `Search limit reached (${search_limit}). Please try again.`,
        {
          variant: "error",
        }
      );
      return true;
    }
    return false;
  }

  //Selects all vessels
  function handleSelectAllVessels() {
    //If all is selected already, then reset the selection
    if (selectedVessel.length === vessels.length) {
      setSelectedVessel([]);
    } else {
      setSelectedVessel(vessels);
    }
  }

  function handleCloseSidebarRefresh() {
    setSideBar(false);
    setForceRefresh((prev) => prev + 1);
  }

  //Called when update location is called
  async function handleRetrieveVesselLocation() {
    if (selectedVessel.length === 0) {
      enqueueSnackbar("No Vessels Selected!", {
        variant: "error",
      });
    } else {
      enqueueSnackbar("Fetching vessels locations", {
        variant: "info",
      });
      const temp = selectedVessel;
      setSelectedVessel([]);
      //Update each vessel card to show loading status
      temp.forEach((vessel) => {
        setVessels((prev) => {
          return prev.map((pv) => {
            if (pv == vessel) {
              pv.loading = true;
            }
            return pv;
          });
        });
      });
      const tempIMO = temp.map((vessel) => vessel.IMO);
      const updatedLoc = await getVesselLocation(tempIMO, socketId, "imo");
      //Set pilotage timeout
      pilotageTimeout(tempIMO);
      //Token is expired so log user out
      if (updatedLoc.status === 401) {
        handleLogout();
      } else if (updatedLoc.status !== 200) {
        temp.forEach((vessel) => {
          setVessels((prev) => {
            return prev.map((pv) => {
              if (pv === vessel) {
                pv.loading = false;
              }
              return pv;
            });
          });
        });
        enqueueSnackbar("Failed to retrieve vessel location!", {
          variant: "error",
        });
        return;
      }

      //Update details of vessels that have been fetched
      updatedLoc.vessels.map((vessel) => {
        setVessels((prev) => {
          const newVessels = prev.map((pv) => {
            if (pv.IMO === vessel.IMO) {
              pv.loading = false;
              pv.latitude = vessel.latitude;
              pv.longitude = vessel.longitude;
              pv.course = vessel.course;
              pv.eta = vessel.eta;
              pv.etd = vessel.etd;
              pv.MMSI = vessel.MMSI;
              pv.destination = vessel.destination;
              pv.callsign = vessel.callsign;
              pv.speed = vessel.speed;
              pv.aliases = vessel.aliases;
              pv.timeStamp = vessel.timeStamp;
              pv.hasPilotageData = null;
            }
            return pv;
          });
          window.localStorage.setItem("VESSELS", JSON.stringify(newVessels));
          return newVessels;
        });
      });

      // spawnPilotageInfoTimer(tempIMO);
    }
  }

  //Called when user double clicks on the vessel name card
  function handleNavigateToVessel(vesselInfo: VesselMapObj) {
    setSelectedVessel([]);
    if (mapRef.current && !vesselInfo.loading) {
      const map = mapRef.current;

      // Calculate the offset for latitude and longitude
      const latitudeOffset = 0.01; // Adjust as needed

      // Calculate the new latitude and longitude values
      const newLatitude =
        Number.parseFloat(vesselInfo.latitude) + latitudeOffset;
      const newLongitude = Number.parseFloat(vesselInfo.longitude);

      // Set selection
      setVesselPopup(vesselInfo.IMO);

      // Fly to the new position
      map.flyTo([newLatitude, newLongitude], 15, {
        duration: 1,
        animate: true,
      });
    }
  }

  //Close the vessel popup
  function handleClosePopup(vesselInfo: VesselMapObj) {
    //Remove selection
    setVesselPopup("");
  }

  //Update the vessel whose marker has been clicked on
  function handleSelectedVessel(vessel: VesselMapObj) {
    if (selectedVessel.find((sv) => sv === vessel)) {
      setSelectedVessel((prev) => {
        return prev.filter((sv) => sv !== vessel);
      });
    } else {
      setSelectedVessel((prev) => [...prev, vessel]);
    }
  }

  function resetSelectedVessel() {
    setSelectedVessel([]);
  }

  const mapRef = useRef<L.Map>(null);

  return (
    <div className="overflow-hidden flex flex-row">
      <Container
        className={`relative overflow-hidden ${isLargeScreen && sideBar ? "w-0" : "w-full"
          };`}
      >
        <VesselMapV2
          mapRef={mapRef}
          isLargeScreen={isLargeScreen}
          sideBar={sideBar}
          vessels={vessels}
          forceRefresh={forceRefresh}
          vesselPopup={vesselPopup}
          handleClosePopup={handleClosePopup}
          userType={userType}
        />
        <div className="absolute right-0 top-0 z-30">
          <button
            className="p-1"
            style={{
              backgroundColor: themeColors.sidebar,
            }}
            onClick={() => {
              if (isLargeScreen) {
                setForceRefresh((prev) => prev + 1);
              }
              setSelectedSideBar("search");
              setSideBar((prev) => {
                if (prev && selectedSideBar === "search") {
                  return false;
                } else {
                  return true;
                }
              });
            }}
          >
            {sideBar && selectedSideBar === "search" ? (
              <ArrowRightIcon htmlColor="white" fontSize="medium" />
            ) : (
              <SearchIcon htmlColor="white" />
            )}
          </button>
        </div>
        <div className="absolute right-0 top-10 z-30">
          <button
            className="p-1"
            style={{
              backgroundColor: themeColors.sidebar,
            }}
            onClick={() => {
              setSelectedSideBar("arrival");
              setSideBar((prev) => {
                if (prev && selectedSideBar === "arrival") {
                  return false;
                } else {
                  return true;
                }
              });
            }}
          >
            {sideBar && selectedSideBar === "arrival" ? (
              <ArrowRightIcon htmlColor="white" fontSize="medium" />
            ) : (
              <CalendarMonth htmlColor="white" />
            )}
          </button>
        </div>
        <div className="absolute right-0 top-20 z-30">
          <button
            className="p-1"
            style={{
              backgroundColor: themeColors.sidebar,
            }}
            onClick={() => {
              navigate(AppRoutes.Settings)
            }}
          >
            {sideBar && selectedSideBar === "alarm" ? (
              <ArrowRightIcon htmlColor="white" fontSize="medium" />
            ) : (
              <Settings htmlColor="white" />
            )}
          </button>
        </div>
      </Container>
      <AnimatePresence>
        {sideBar && (
          <SideBarContainer
            initial={{ width: 0, height: "screen" }}
            animate={{
              width: isLargeScreen
                ? "100vw"
                : selectedSideBar === "arrival"
                  ? "60%"
                  : "40%",
            }}
            exit={{ width: 0 }}
            transition={{ duration: isLargeScreen ? "0" : "0.25" }}
            style={{
              minWidth: isLargeScreen ? "320px" : "0px",
            }}
          >
            <div style={{ height: "calc(100vh - 10px)", overflowY: "auto" }}>
              {selectedSideBar === "search" && (
                <SideBarContent
                  pilotageTimeout={pilotageTimeout}
                  sid={socket.id}
                  handleRetrieveVesselLocation={handleRetrieveVesselLocation}
                  handleSelectAllVessels={handleSelectAllVessels}
                  handleRemoveVessel={handleRemoveVessel}
                  handleRemoveVesselIMO={handleRemoveVesselIMO}
                  handleAddToFleetIMO={handleAddToFleetIMO}
                  handleAddToFleetName={handleAddToFleetName}
                  selectedVessel={selectedVessel}
                  handleNavigateToVessel={handleNavigateToVessel}
                  handleSelectedVessel={handleSelectedVessel}
                  vessels={vessels}
                  handleCloseSidebar={handleCloseSidebarRefresh}
                  handleSetAvailColors={handleSetAvailColors}
                  checkVesselInFleet={checkVesselInFleet}
                  updateFleet={updateFleet}
                  availColors={availColors}
                  isLargeScreen={isLargeScreen}
                  handleLogout={handleLogout}
                  handleBookmark={handleBookmark}
                  logout={logout}
                  checkSearchLimit={checkSearchLimit}
                  resetSelectedVessel={resetSelectedVessel}
                  userType={userType}
                />
              )}
              {selectedSideBar === "arrival" && (
                <DueToArriveSideBarContent
                  handleLogout={logout}
                  isLargeScreen={isLargeScreen}
                  handleCloseSidebar={handleCloseSidebarRefresh}
                  logout={logout}
                />
              )}
            </div>
          </SideBarContainer>
        )}
      </AnimatePresence>
      {openDeletionConfirmationModal && (
        <DeletionConfirmationModal
          handleMultipleVesselRemove={handleSelectedVesselsRemove}
          handleConfirmationModalClose={() => {
            setOpenDeletionConfirmationModal(false);
          }}
          selectedVessels={selectedVessel}
        />
      )}
    </div>
  );
};

export default VesselLocation;
