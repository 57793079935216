import { Logout } from "@mui/icons-material";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PasswordResetForm from "../components/ResetPasswordForm";
import { AppRoutes, themeColors } from "../lib/constants";
import useAuthStore from "../stores/authStore";
import useUserStore from "../stores/userStore";
import { Image } from "../styles/global";
import { AdminManageTeamComponent } from "../components/AdminManageTeamComponent";
import { SgtradexLogoComponent } from "../assets/sgtradexLogo"

const breakpoints = {
  mobile: '480px',
  tablet: '768px',
  laptop: '1024px',
  desktop: '1200px'
};

const MainContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${themeColors.sidebar};
`;

const Header = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4rem;
  background-color: ${themeColors.headerBar};
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const ContentBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 1rem;
  gap: 1rem;
  @media (min-width: ${breakpoints.tablet}) {
    padding: 1.5rem;
  }
  @media (min-width: ${breakpoints.laptop}) {
    padding: 2rem;
    gap: 2rem;
    align-items: center;
  }
  @media (min-width: ${breakpoints.desktop}) {
    padding: 2rem;
    gap: 2rem;
    align-items: center;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${themeColors.contentBackground};
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  @media (min-width: ${breakpoints.tablet}) {
    padding: 1.5rem;
  }
  @media (min-width: ${breakpoints.laptop}) {
    padding: 2rem;
    max-width: 800px;
    width: 75%;
  }
  @media (min-width: ${breakpoints.desktop}) {
    padding: 2rem;
    max-width: 1200px;
    width: 75%;
  }
`;

const LogoContainer = styled.div`
  height: 2.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const SettingsPage = () => {
  const navigate = useNavigate();
  const { logout } = useAuthStore();
  const { isAdmin } = useUserStore()

  function handleLogout() {
    window.localStorage.removeItem("ALERTS");
    window.localStorage.removeItem("COLOR");
    window.localStorage.removeItem("VESSELS");
    logout();
    navigate(AppRoutes.Login);
  }

  return (
    <MainContainer>
      <Header>
        <LogoContainer
          onClick={
            () => navigate(AppRoutes.VesselQuery)
          }
        >
          <SgtradexLogoComponent showSubtitle={false} />
        </LogoContainer>
        <HeaderActions>
          <Button
            className="w-28 py-2 text-black font-bold"
            color="error"
            variant="contained"
            startIcon={<Logout />}
            onClick={() => {
              handleLogout();
            }}
          >
            Logout
          </Button>
        </HeaderActions>
      </Header>
      <ContentBox>
        <h1 className='text-3xl font-bold text-white'>
          Settings
        </h1>
        <Content>
          <h2 className="text-2xl font-bold text-white mb-4">
            Account Settings
          </h2>
          <PasswordResetForm />
        </Content>
        {
          isAdmin && (
            <>
              <Content>
                <h2 className="text-2xl font-bold text-white mb-4">
                  Team Members
                </h2>
                <AdminManageTeamComponent />
              </Content>
              {/* TODO: Implement team usage statistics
              <Content>
                <h2 className="text-2xl font-bold text-white mb-4">
                  Team Usage
                </h2>
              </Content> */}
            </>
          )
        }
      </ContentBox>
    </MainContainer>
  );
};