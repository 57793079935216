import styled, { css } from "styled-components";
import AppColors from "./colors";
import { createTheme, Theme } from "@mui/material/styles";

const sharedFlexCenter = css`
    display: flex;
    align-items: center;
    justify-content: center;
`

const sharedFlexSpaceBetween = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const sharedButtonStyle = css`
    background: ${AppColors.ThemeLightPurple};
    padding: 1rem;
`

const vesselSearchButtonStyle = css`
    width: 8rem;
    background: black;
    padding: 1rem;
    &:hover{
        background: grey;
    }
`
const Image = styled.img`
    width:100%;
    height:100%;
`
export const Section = styled.div`
  ${sharedFlexCenter}
  flex-direction: column;
  min-height: 100vh;
  margin: auto 0;
  padding: 0;
  background: linear-gradient(to bottom, #1845ad, #b92f90);
`;

export const muiDarkTheme = createTheme({
    palette: {
        mode: 'dark'
    },
    typography: {
        fontFamily: [
            'Inter',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

export {
    sharedFlexCenter,
    sharedFlexSpaceBetween,
    sharedButtonStyle,
    Image,
    vesselSearchButtonStyle
}