import "@fontsource/inter";
import "./App.css";
import { Providers } from "./lib/Providers";

function App() {

  return (
    <Providers />
  )
}

export default App;
